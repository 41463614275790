import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Text, Button, Section, Span, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, StackItem, Stack, SocialMedia, Menu } from "@quarkly/components";
import * as Components from "components";
import { MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"stonks9800"} />
		<Helmet>
			<title>
				STONKS-9800 Press-kit
			</title>
			<meta name={"description"} content={"Stock Market Simulator"} />
			<meta property={"og:title"} content={"STONKS-9800 Press-kit"} />
			<meta property={"og:description"} content={"Stock Market Simulator"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2021-04-12T15:34:28.541Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon.png?v=2021-05-09T21:33:26.579Z"} />
			<meta name={"msapplication-TileColor"} content={"#f8d2d2"} />
		</Helmet>
		<Box text-align="center" margin="-7px 0px 0px 0px">
			<Components.Menuu padding="32px 0px 24px 0px">
				<Override slot="menuOverride3" display="none" />
				<Override slot="text" text-transform="uppercase" color="#000000">
					<Link
						color="#000000"
						text-indent="0"
						text-overflow="clip"
						text-decoration-line="initial"
						href="https://ternoxgames.com/"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						hyphens="manual"
					>
						Ternoxgames.com
					</Link>
				</Override>
			</Components.Menuu>
			<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-3-f20ef3fa0153.gif?v=2021-05-09T22:16:39.762Z" padding="0px 8px 0px 0px" />
			<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-3-042fd3120ae4.gif?v=2021-05-09T22:16:39.761Z" padding="0px 8px 0px 0px" />
			<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-3-5321e6fd1842.gif?v=2021-05-09T22:16:39.756Z" padding="0px 8px 0px 0px" />
			<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-3-55b8c8178cc6.gif?v=2021-05-09T22:16:39.755Z" padding="0px 8px 0px 0px" />
			<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-3-d98ca58b7ca9.gif?v=2021-05-09T22:16:39.753Z" />
		</Box>
		<Section padding="140px 0" sm-padding="40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2021-04-12T15:34:28.541Z) top/cover" margin="-5px 0 0 0">
			<Box
				background="--color-light"
				color="#04080C"
				position="static"
				align-self="flex-end"
				max-width="243px"
				padding="50px 80px 80px 50px"
			>
				<Text margin="0 0 12px 0" width="296px" as="h2" font="--headline2">
					STONKS-9800{"\n\n"}
				</Text>
				<Text font="--base">
					A simulator of a businessman on the Japanese stock market in the 80s and 90s. Chill, catch a retro vibe, and watch your profits grow in the text-based game STONKS-9800.{"  "}
				</Text>
				<Button target="_blank" href="https://discord.com/invite/cR49SHktjW/">
					Join Discord Server
				</Button>
			</Box>
		</Section>
		<Section
			border-width="8px"
			border-style="solid"
			width="100% border-box"
			padding="34px 0 64px 0"
			sm-padding="40px 0"
			color="--dark"
			background="#ffeb78"
			border-color="#f9f9f9"
		>
			<Stack>
				<StackItem width="33.33%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						font="--headline3"
						margin="12px 0"
						text-transform="uppercase"
						text-shadow="2px 0 0 #1ef7ff"
						as="h3"
					>
						Buy Stocks
					</Text>
					<Text as="p" font="--base" margin="12px 0" color="--darkL1">
						- Buy stocks, get dividends, monitor the prices;
						<br />
						- Your decisions affect the market – and your life;
						<br />
						- Keep an eye on your health and do not overwork, lest you end up in the hospital;
					</Text>
					<Image width="100%" height="100%" src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/stonks_dia.gif?v=2021-05-09T22:28:03.338Z" />
				</StackItem>
				<StackItem width="33.33%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						font="--headline3"
						margin="12px 0"
						text-shadow="2px 0 0 #1ef7ff"
						text-transform="uppercase"
						as="h3"
					>
						Catch retro vibe
					</Text>
					<Text as="p" font="--base" margin="12px 0" color="--darkL1">
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							- Mini-games: pachinko and betting on horse races
							<br />
							- Relive the period Japanese asset price bubble
							<br />
							- Meet Yakuza
							<br />
							- Manage your time
						</Span>
					</Text>
					<Image width="100%" height="100%" src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/pachinkoo.gif?v=2021-05-09T22:11:54.844Z" />
				</StackItem>
				<StackItem width="33.33%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						as="h3"
						font="--headline3"
						margin="12px 0"
						text-shadow="2px 0 0 #1ef7ff"
						text-transform="uppercase"
					>
						Get Stonks{"\n\n"}
					</Text>
					<Text font="--base" margin="12px 0" color="--darkL1" as="p">
						<Span
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
						>
							- 
Manage the company, having a controlling interest
							<br />
							{"\n\n"}- 
Buy your own real estate and cars, increasing your level of comfort
							<br />
							- Watch how your profits grow
						</Span>
					</Text>
					<Image width="100%" height="100%" src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/stonks_lady.gif?v=2021-05-09T22:28:03.369Z" />
				</StackItem>
			</Stack>
		</Section>
		<Components.Htmlform />
		<Components.QuarklycommunityKitYouTube url="https://youtu.be/z47QO_eFWCU" disablekb={false} modestbranding />
		<Section
			color="--light"
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			position="relative"
			background="#332c2d"
			sm-height="auto"
			md-height="400px"
		>
			<Stack>
				<StackItem md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="0x 0 0 0"
						max-width="360px"
						position="relative"
					>
						<Icon
							icon={MdEmail}
							top="13px"
							size="48px"
							left="6px"
							category="md"
							bottom="auto"
							height="48px"
							right="auto"
							width="48px"
							position="absolute"
						/>
						<Text as="h4" margin="6px 0" font="--base">
							PRESS CONTACT
						</Text>
						<Text font="--headline3" as="p" margin="6px 0">
							<Link href="mailto:me@ternoxgames.com" text-decoration="none" hover-text-decoration="underline" color="--light">
								me@ternoxgames.com
							</Link>
						</Text>
					</Box>
				</StackItem>
				<StackItem md-width="100%" width="50%" lg-width="100%">
					<Override slot="StackItemContent" width="100%" lg-width="100%" />
					<Components.Stonks md-width="100%" width="100%" lg-width="100%" />
				</StackItem>
			</Stack>
			<SocialMedia
				twitter="https://twitter.com/taimumari"
				youtube="https://www.youtube.com/channel/UC1rKs1kvj_f1S7lwpHGZ9TQ/"
				display="flex"
				align-self="flex-start"
				instagram="https://www.instagram.com/taimumari/"
				icon-size="32px"
				facebook="https://www.facebook.com/taimumari"
				telegram="https://t.me/ternox"
				text-align="left"
				position="relative"
				discord="https://discord.com/invite/cR49SHktjW/"
			/>
		</Section>
		<Section
			padding="60px 0"
			background="#c6f4d7"
			display="flex"
			flex-direction="column"
			border-color="#f9f9f9"
			border-style="solid"
			width="100% border-box"
			sm-padding="40px 0"
			sm-min-height="auto"
			border-width="8px"
		>
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				max-width="850px"
				color="--grey"
				margin="0px 0px 8px 0px"
			>
				OUR FRIEND
			</Text>
			<Stack font="--base" color="--grey">
				<StackItem width="50%" md-width="100%">
					<Text
						max-width="850px"
						as="h1"
						margin="0px"
						font="--headline3"
						md-font="--headline2"
						color="--dark"
					>
						About STONKS-9800 in Press
					</Text>
				</StackItem>
				<StackItem width="50%" md-width="100%">
					<Override slot="StackItemContent" flex-wrap="wrap" />
					<Link
						href="https://www.pcgamer.com/live-the-economy-of-1980s-japan-in-stonks-9800/"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						PC Gamer
					</Link>
					<Link
						href="https://www.ign.com/videos/stonks-9800-official-early-access-launch-trailer"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						IGN
					</Link>
					<Link
						href="https://automaton-media.com/articles/newsjp/20210510-160725/"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						Automaton-Media.com
					</Link>
					<Link
						href="https://www.rockpapershotgun.com/stonks-9800-city-pop-investment-simulator-early-access"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						Rock Paper Shotgun
					</Link>
					<Link
						href="https://nichegamer.com/niche-games-spotlight-stonks-9800/"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						Niche Gamer
					</Link>
					<Link
						href="https://www.destructoid.com/financial-simulator-stonks-9800-hits-early-access-today/"
						padding="7px 20px 7px 20px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						border-radius="2px"
						hover-background="--color-darkL1"
						height="27px"
					>
						Destructoid
					</Link>
					<Link
						padding="7px 20px 7px 20px"
						display="inline-block"
						height="27px"
						text-decoration-line="initial"
						border-radius="2px"
						hover-background="--color-darkL1"
						href="https://www.gematsu.com/2021/11/stonks-9800-stock-market-simulator-launches-in-q1-2022-for-pc-later-in-2022-for-ps5-xbox-series-ps4-xbox-one-and-switch"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
					>
						Gematsu
					</Link>
					<Link
						padding="7px 20px 7px 20px"
						display="inline-block"
						height="27px"
						text-decoration-line="initial"
						border-radius="2px"
						hover-background="--color-darkL1"
						href="https://news.denfaminicogamer.jp/news/210716g"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
					>
						denfaminicogame.jp
					</Link>
					<Link
						padding="7px 20px 7px 20px"
						display="inline-block"
						height="27px"
						text-decoration-line="initial"
						border-radius="2px"
						hover-background="--color-darkL1"
						href="https://mezha.media/en/2023/06/13/stonks-9800-a-ukrainian-simulator-of-the-japanese-stock-market-of-the-80s/"
						target="_blank"
						background="--color-primary"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
					>
						Mezha.Media
					</Link>
					<Link
						display="inline-block"
						hover-background="--color-darkL1"
						href="https://news.nicovideo.jp/watch/nw10144677"
						text-decoration-line="initial"
						border-radius="2px"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						height="27px"
						target="_blank"
						background="--color-primary"
						padding="7px 20px 7px 20px"
					>
						News Nicovideo
					</Link>
					<Link
						display="inline-block"
						hover-background="--color-darkL1"
						href="https://www.gamersheroes.com/honest-game-reviews/stonks-9800-stock-market-simulator-early-access-review/"
						text-decoration-line="initial"
						border-radius="2px"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						height="27px"
						target="_blank"
						background="--color-primary"
						padding="7px 20px 7px 20px"
					>
						Gamers Heroes
					</Link>
					<Link
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						border-radius="2px"
						display="inline-block"
						hover-background="--color-darkL1"
						target="_blank"
						background="--color-primary"
						padding="7px 20px 7px 20px"
						height="27px"
						href="https://news.yahoo.co.jp/articles/dbc3681a0d9ccf50023f2d3fe0891c187f012106"
						text-decoration-line="initial"
					>
						Yahoo Japan
					</Link>
					<Link
						padding="7px 20px 7px 20px"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						hover-background="--color-darkL1"
						height="27px"
						background="--color-primary"
						target="_blank"
						text-decoration-line="initial"
						border-radius="2px"
						display="inline-block"
						href="https://www.nintendolife.com/news/2021/11/stonks-9800-stock-market-simulator-launches-on-nintendo-switch-in-2022"
					>
						Nintendo Life
					</Link>
					<Link
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						hover-background="--color-darkL1"
						href="https://nintendowire.com/news/2021/11/15/stonks-9800-stock-market-simulator-rolling-onto-switch-in-late-2022/"
						target="_blank"
						background="--color-primary"
						padding="7px 20px 7px 20px"
						border-radius="2px"
						height="27px"
					>
						Nintendo Wire
					</Link>
					<Link
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
						text-decoration-line="initial"
						display="inline-block"
						hover-background="--color-darkL1"
						href="https://www.gamegrin.com/previews/stonks-9800-stock-market-simulator-preview/"
						target="_blank"
						background="--color-primary"
						padding="7px 20px 7px 20px"
						border-radius="2px"
						height="27px"
					>
						GameGrin
					</Link>
					<Link
						padding="7px 20px 7px 20px"
						border-radius="2px"
						display="inline-block"
						height="27px"
						target="_blank"
						background="--color-primary"
						text-decoration-line="initial"
						hover-background="--color-darkL1"
						margin="3px 3px 3px 3px"
						color="#F7FBFF"
					>
						And You ❤
					</Link>
				</StackItem>
			</Stack>
		</Section>
		<Section background="#ffa078 linear-gradient(0deg,rgba(0,0,0,0) 0%,#ff8c8c 100%)" color="--dark">
			<Box position="relative" max-width="800px" padding="0px 0px 0px 10px" sm-padding="54px 0 0 0">
				<Text
					as="h3"
					font="--headline2"
					margin="10px 0"
					text-shadow="2px 0 0 #1ef7ff"
					text-transform="uppercase"
				>
					screenshots
				</Text>
			</Box>
			<Box display="flex" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						max-width="100%"
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13:48:25.350Z"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2003-59-05-792.png?v=2023-06-12T13%3A48%3A25.350Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						width="100%"
						max-width="100%"
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13:48:36.812Z"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13%3A48%3A36.812Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box display="flex" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13:48:50.553Z"
						max-width="100%"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-23%2001-04-58-615.png?v=2023-06-12T13%3A48%3A50.553Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13:49:03.879Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2005-00-04-037.png?v=2023-06-12T13%3A49%3A03.879Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			background="rgba(255, 255, 255, 0.37)"
			padding="60px 0"
			sm-padding="40px 0"
			display="flex"
			flex-direction="column"
		>
			<Section font="--base" color="--dark" sm-padding="40px 0">
				<Stack>
					<StackItem width="100%" lg-width="100%" text-align="center" font="300 16px/32px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif">
						<Override slot="StackItemContent" lg-margin-bottom="24px" flex-direction="column" />
						<Text color="--grey" letter-spacing="1px" text-transform="uppercase" margin="0 0 10px 0">
							WHEN?
						</Text>
						<Text margin="0" as="h2" font="normal 500 28px/1.5 --fontFamily-sans" md-font="--headline3">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Already in Early Access on Steam!
								<br />
								<br />
							</Span>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Span
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									overflow-wrap="normal"
									user-select="auto"
									pointer-events="auto"
								>
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									/>
								</Span>
							</Span>
							<Strong>
								Full release in 2024
							</Strong>
							<br />
							for PC and consoles
						</Text>
					</StackItem>
				</Stack>
			</Section>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="40px 0 40px 0"
			sm-padding="40px 0"
		>
			<Text
				letter-spacing="5px"
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
			>
				TERNOX GAMES
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				WHO IS IT?
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Hi, I'm solo indie video game developer from Ukraine - TERNOX.
				<br />
				<br />
				{"\n"}It all started in year 2008, when I, being a teenager, downloaded Game Maker 6. As years passed, I dropped this matter a few times, then tried creating something again, gaining skills in GameMaker: Studio as result. First game that I will make in GMS namely is{" "}
				<Strong
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					overflow-wrap="normal"
					word-break="normal"
				>
					Taimumari
				</Strong>
				.
			</Text>
			<Link
				hover-background="--color-darkL1"
				text-align="center"
				margin="3px auto 3px auto"
				text-decoration-line="initial"
				border-radius="2px"
				display="inline-block"
				height="26px"
				width="50%"
				href="https://ternoxgames.com"
				background="--color-green"
				padding="17px 20px 7px 20px"
				color="#F7FBFF"
			>
				CHECK MY GAMES
			</Link>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
				justify-content="center"
				font="--lead"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
		</Section>
	</Theme>;
});